import React, { useCallback, useMemo, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './SmsVertificationModal.scss';
import MaterialCheckbox from '@components/Checkbox';
import { phoneRegex } from '@core/utils';
import { httpService } from '@core/http/HttpService';
import { useSelector } from '@redux/useSelector';
import classNames from 'classnames';
import { modalService } from '@core/modals/ModalService';
import { authService } from '@core/auth/AuthService';
import PhoneNumberInput from '@components/PhoneNumberInput';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '28px',
    height: '56px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    '&.mobile': {
      fontSize: '13px',
    },
  },
}));

function SmsVertificationModal(props) {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const { args, dismiss } = props;
  const { userConfig } = args;
  const [input, setInput] = useState(userConfig?.mobile || '');
  const [showError, setShowError] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const classes = useStyles(props);

  const cancel = useCallback(() => dismiss(), [dismiss]);

  const onInsertInput = (value: string) => {
    setShowError(false);
    setInput(value ? `+${value}` : '');
  };

  const onValidate = async () => {
    try {
      const isValid = validate(input);
      setShowError(!isValid);

      if (isValid) {
        await httpService.api({
          type: 'sendSMSvertification',
          data: {
            mobile: input,
          },
        });
        dismiss(input);
      }
    } catch (e) {
      await modalService.openAlert({
        text: `errors.${e?.data?.message?.code}`,
        headerText: `errors.${e?.data?.message?.code}-header`,
        showCloseBtn: true,
      });
      cancel();
      authService.logout();
    }
  };

  const validate = (value: string) => {
    return phoneRegex.test(value) && value.replace(/[^0-9]/g, '').length <= 15;
  };

  const onDontShowPressed = async () => {
    try {
      await httpService.api({
        type: 'showSMSvertification',
        data: { show: dontShowAgain },
      });
      setDontShowAgain(!dontShowAgain);
    } catch (e) {}
  };

  const errorStyle = useMemo(
    () => (showError ? { border: 'solid', borderColor: 'red', borderRadius: 6 } : {}),
    [showError]
  );

  return (
    <div className={classNames(styles.wrapper, isMobileView && styles.wrapperMobile)}>
      <div style={{ display: 'flex' }}>
        <div
          className={classNames(
            classes.modalHeader,
            'ellipsis-overflow',
            isMobileView && 'mobile'
          )}>
          <I18n element="div">mobile-number-validation.popup-header</I18n>
        </div>
        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>
      <div className={classNames(styles.detailsContainer, isMobileView && styles.mobileMode)}>
        <div className={classNames(styles.headerText, isMobileView && styles.mobileMode)}>
          <I18n noEllipsis element="div">
            mobile-number-validation.popup.new-functionality
          </I18n>
          <I18n noEllipsis element="div">
            mobile-number-validation.popup.sms-notification-now-available
          </I18n>
          <I18n noEllipsis element="div">
            mobile-number-validation.popup-text
          </I18n>
        </div>
        <div className={classNames(styles.rowWithError, isMobileView && styles.mobileMode)}>
          <I18n className={styles.text} element="div">
            mobile-number-validation.mobile-number
          </I18n>
          <div>
            <div>
              <PhoneNumberInput
                value={input}
                width={isMobileView ? 130 : 198}
                fontSize={isMobileView ? '12px' : null}
                onChange={(value) => onInsertInput(value)}
              />
            </div>
            <div className={styles.error}>
              {showError && <I18n>mobile-number-validation.phone-number-not-valid</I18n>}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.checkbox}>
        <MaterialCheckbox
          color="primary"
          checked={dontShowAgain}
          onChange={() => onDontShowPressed()}
        />
        <I18n className={styles.checkboxText}>general.dont-show-again</I18n>
      </div>

      <div className={classNames(styles.modalButtons, isMobileView && styles.mobileMode)}>
        <Button
          disabled={false}
          onClick={cancel}
          mode={['cancel', isMobileView ? 'default' : 'bigFont']}>
          <I18n>general.cancel</I18n>
        </Button>

        <Button
          mode={isMobileView ? 'default' : 'bigFont'}
          disabled={showError}
          onClick={onValidate}
          styles={{ marginLeft: 13 }}>
          <I18n>mobile-number-validation.validate</I18n>
        </Button>
      </div>
    </div>
  );
}

export default SmsVertificationModal;
