import { i18nService } from '@core/i18n/I18nService';
import { expressionBuilderGroupNames } from '@core/mapsAndDefinitions';
import { expressionBuilderRegex } from '@core/regex';
import { mentionHtmlRegex } from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor/RichTextEditor.utils';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { compact } from 'lodash';

const allowedChars = '0123456789+-*/().% ^√';
const digits = '0123456789';
const operators = '+-*/^√%';
const signOperators = '-';
const varsDelimiter = '#@#@SpongeBob#';
const prohibitedCombinations = ['^ #', ' √ #', ' √', '^ ', '  ^', '√√', '%%'];

const variableRegex = /#@#@SpongeBob#(.*?)#@#@SpongeBob#/;

const expressionDataSourceRegex =
  /\[(assetTags|systemProperties|assetProperties|tagTypes)\.[^\[\]]*\]/g;

enum StateMachine {
  Initial = 'Initial', // Initial State
  Valid = 'Valid', // Valid State
  WaitingForNumber = 'Waiting for a number', // Sign Operator waiting for a number
  Error = 'Error', // Invalid syntax
}

const isAllowedChar = (char: string, origininatesFrom: number | string) => {
  const chars = origininatesFrom === 'conditionBuilder' ? allowedChars.split('^')[0] : allowedChars;

  if (chars.includes(char)) return true;

  return false;
};

const isDigit = (char: string) => {
  if (digits.includes(char)) return true;

  return false;
};

const isSpacesToken = (text: string) => {
  return /^ +$/.test(text);
};

const isNumberToken = (text: string) => {
  return /^(\d+|\d+\.\d+)$/.test(text);
};

const isZeroToken = (text: string) => {
  return /^(0+|0+\.0+)$/.test(text);
};

const isOperator = (char: string) => {
  if (operators.includes(char)) return true;

  return false;
};

const isSignOperator = (char: string) => {
  if (signOperators.includes(char)) return true;

  return false;
};

const isRootOperator = (char: string) => {
  if (char === '√') return true;

  return false;
};

const isOpeningBracket = (char: string) => {
  if (char === '(') return true;

  return false;
};

const isClosingBracket = (char: string) => {
  if (char === ')') return true;

  return false;
};

const isDecimalPoint = (char: string) => {
  if (char === '.') return true;

  return false;
};

const getPositionToCurrentToken = (tokens, currentTokenIndex) => {
  let pos = 0;
  for (let i = 0; i < currentTokenIndex; i++) pos += tokens[i].length;

  return pos;
};

const convertHtmlToExpression = (html: string): { expression: string; expressionWithVars } => {
  let newHtml = html;
  // We want to mark the mentions before we convert the html to plain text
  // I'm converting them in the expression to a number, but if there are 2 mentions, one after the other, then
  // I can't distinguish between them. We want need the original text of the mention in order to show it in the error message
  newHtml = newHtml.replace(mentionHtmlRegex, `${varsDelimiter}#$2${varsDelimiter}`);

  const { contentBlocks, entityMap } = htmlToDraft(newHtml);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  let expression = contentState.getPlainText(' ');
  let expressionWithVars = expression;
  while (variableRegex.test(expression)) {
    const match = expression.match(variableRegex)[1];
    expression = expression.replace(variableRegex, ' ' + '1'.repeat(match.length) + ' ');
    expressionWithVars = expressionWithVars.replace(variableRegex, ' $1 ');
  }

  return { expression, expressionWithVars };
};

export const convertHtmlToExpressionForConditionBuilder = (html: string): string => {
  let newHtml = html;

  newHtml = newHtml.replaceAll(mentionHtmlRegex, `[$2]`).replaceAll('@data-type@', '');

  const { contentBlocks, entityMap } = htmlToDraft(newHtml);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const initialExpression = contentState.getPlainText(' ');

  if (initialExpression.includes('^') || initialExpression.includes('√')) {
    return convertExpressionWithPowersOrRootsForBE(initialExpression);
  } else {
    return initialExpression;
  }
};

export function convertExpressionWithPowersOrRootsForBE(expression: string): string {
  const subexpressions = new Set([
    ...[...expression.matchAll(expressionBuilderRegex.toBE.power.tagRegex)].map((item) => item[0]),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.power.numberRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.power.expressionRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.nthRoot.tagRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.nthRoot.numberRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.nthRoot.expressionRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.squareRoot.tagRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.squareRoot.numberRegex)].map(
      (item) => item[0]
    ),
    ...[...expression.matchAll(expressionBuilderRegex.toBE.squareRoot.expressionRegex)].map(
      (item) => item[0]
    ),
  ]);

  const splitSubexpressions = compact(
    Array.from(subexpressions).map((item) => {
      if (item.includes('^')) {
        return [item, ...item.split('^')];
      } else if (item.includes('√')) {
        return [item, ...item.split('√')];
      }
    })
  );

  const powerAndRootSubexpressions = splitSubexpressions.map((item) => {
    if (item[0].includes('^')) {
      return [item[0], `power(${item[1]}, ${item[2]})`];
    } else if (item[0].includes('√')) {
      return [item[0], `nth_root(${item[2]}, ${item[1] === '' ? '2' : item[1]})`];
    }
  });

  const resultExpression = powerAndRootSubexpressions.reduce(
    (s, m, i) => s.replace(powerAndRootSubexpressions[i][0], powerAndRootSubexpressions[i][1]),
    expression
  );

  return resultExpression;
}

export const validateExpression = (
  html,
  languageId,
  allowEmpty,
  origininatesFrom
): { isValid: boolean; error: string } => {
  const brackets = [];
  let currentState = StateMachine.Initial;
  const { expression, expressionWithVars } = convertHtmlToExpression(html);
  const tokens = [];
  let currentToken = '';

  for (let i = 0; i < expression.length; i++) {
    const currentChar = expression[i];
    const currentCharInExpressionWithVars = expressionWithVars[i];

    if (!isAllowedChar(currentChar, origininatesFrom))
      return {
        isValid: false,
        error: i18nService.translate(
          'events-template.edit-template.expression-builder.character-not-supported',
          languageId,
          { currentChar }
        ),
      };

    if (isDigit(currentChar) || isDecimalPoint(currentChar)) {
      if (currentCharInExpressionWithVars !== '#') currentToken += currentChar;
      else {
        if (currentToken !== '') tokens.push(currentToken);
        currentToken = currentChar;
      }
    } else {
      if (currentToken !== '') tokens.push(currentToken);

      tokens.push(currentChar);
      currentToken = '';
    }
  }

  if (currentToken !== '') tokens.push(currentToken);

  // combine consecutive spaces into 1 token space
  for (let i = 1; i < tokens.length; i++) {
    if (isSpacesToken(tokens[i]) && isSpacesToken(tokens[i - 1])) {
      tokens[i - 1] += tokens[i];
      tokens.splice(i, 1);
      i--;
    }
  }

  // check brackets (We want to show error about brackets before other errors)
  for (let i = 0; i < tokens.length; i++) {
    const currentToken = tokens[i];

    if (isOpeningBracket(currentToken)) brackets.push('(');

    if (isClosingBracket(currentToken)) {
      if (brackets.length === 0)
        return {
          isValid: false,
          error: i18nService.translate(
            'events-template.edit-template.expression-builder.incorrect-use-of-parenthesis',
            languageId,
            { text: expressionWithVars.substr(0, getPositionToCurrentToken(tokens, i) + 1) }
          ),
        };

      brackets.pop();
    }
  }

  if (brackets.length !== 0) {
    const lastIndexOfParenthesis = Math.max(
      expressionWithVars.lastIndexOf('('),
      expressionWithVars.lastIndexOf(')')
    );
    return {
      isValid: false,
      error: i18nService.translate(
        'events-template.edit-template.expression-builder.incorrect-use-of-parenthesis',
        languageId,
        { text: expressionWithVars.substr(lastIndexOfParenthesis) }
      ),
    };
  }

  // Now checking general syntax
  for (let i = 0; i < tokens.length; i++) {
    const currentToken = tokens[i];
    const fromVarExpressionToken = expressionWithVars.substr(
      getPositionToCurrentToken(tokens, i),
      currentToken.length
    );
    const prevNonSpaceToken =
      tokens
        .slice(0, i)
        .filter((t) => !isSpacesToken(t))
        .pop() || '';
    const fromVarExpressionTokenNonSpaceToken = expressionWithVars.substr(
      getPositionToCurrentToken(tokens, tokens.slice(0, i).lastIndexOf(prevNonSpaceToken)),
      prevNonSpaceToken.length
    );

    if (
      !isOpeningBracket(currentToken) &&
      !isClosingBracket(currentToken) &&
      !isSpacesToken(currentToken) &&
      !isOperator(currentToken) &&
      !isNumberToken(currentToken)
    ) {
      return {
        isValid: false,
        error: i18nService.translate(
          'events-template.edit-template.expression-builder.invalid-expression-text',
          languageId,
          { text: currentToken }
        ),
      };
    }

    switch (currentState) {
      case StateMachine.Initial:
        if (
          isOpeningBracket(currentToken) ||
          isClosingBracket(currentToken) ||
          isSpacesToken(currentToken)
        ) {
          // current state does not change
        } else if (isSignOperator(currentToken) || isRootOperator(currentToken))
          currentState = StateMachine.WaitingForNumber;
        else if (isOperator(currentToken)) {
          currentState = StateMachine.Error;
          return {
            isValid: false,
            error: i18nService.translate(
              'events-template.edit-template.expression-builder.invalid-expression-text',
              languageId,
              { text: `${fromVarExpressionTokenNonSpaceToken} ${fromVarExpressionToken}` }
            ),
          };
        } else if (isNumberToken(currentToken)) {
          currentState = StateMachine.Valid;
        }
        break;
      // OLD VERSION OF THE CODE ABOVE - Not working with the second root in expressions like this: √9+√9
      // case StateMachine.WaitingForNumber:
      //   if (
      //     isOpeningBracket(currentToken) ||
      //     (isOperator(currentToken) && !isSpacesToken(currentToken))
      //   ) {
      //     // current state does not change
      //   } else if (isNumberToken(currentToken)) {
      //     currentState = StateMachine.Valid;
      //   } else if (
      //     isClosingBracket(currentToken) ||
      //     !isRootOperator(currentToken)
      //     // (isOperator(currentToken) &&
      //     //   !(
      //     //     isSignOperator(currentToken) &&
      //     //     isRootOperator(currentToken) &&
      //     //     isOpeningBracket(tokens[i - 1])
      //     //   ))
      //   ) {
      case StateMachine.WaitingForNumber:
        if (
          isOpeningBracket(currentToken) ||
          isRootOperator(currentToken) ||
          isSpacesToken(currentToken)
        ) {
          // current state does not change
        } else if (isNumberToken(currentToken)) currentState = StateMachine.Valid;
        else if (
          isClosingBracket(currentToken) ||
          (isOperator(currentToken) &&
            !(isSignOperator(currentToken) && isOpeningBracket(tokens[i - 1])))
        ) {
          currentState = StateMachine.Error;
          return {
            isValid: false,
            error: i18nService.translate(
              'events-template.edit-template.expression-builder.invalid-expression-text',
              languageId,
              { text: `${fromVarExpressionTokenNonSpaceToken} ${fromVarExpressionToken}` }
            ),
          };
        }
        break;
      case StateMachine.Valid:
        if (isSpacesToken(currentToken) || isClosingBracket(currentToken)) {
          // current state does not change
        } else if (isOpeningBracket(currentToken)) {
          currentState = StateMachine.Error;
          return {
            isValid: false,
            error: i18nService.translate(
              'events-template.edit-template.expression-builder.invalid-expression-text',
              languageId,
              { text: `${fromVarExpressionTokenNonSpaceToken} ${fromVarExpressionToken}` }
            ),
          };
        } else if (isNumberToken(currentToken)) {
          currentState = StateMachine.Error;
          return {
            isValid: false,
            error: i18nService.translate(
              'events-template.edit-template.expression-builder.missing-operation',
              languageId,
              { text: `${fromVarExpressionTokenNonSpaceToken} ${fromVarExpressionToken}` }
            ),
          };
        } else if (isOperator(currentToken)) {
          currentState = StateMachine.WaitingForNumber;
        }
        break;
    }
  }

  if (
    (currentState !== StateMachine.Initial && currentState !== StateMachine.Valid) ||
    prohibitedCombinations.some(
      (pc) =>
        expressionWithVars.includes(pc) ||
        expressionWithVars.match(expressionBuilderRegex.general.doublePower)
    )
  ) {
    return {
      isValid: false,
      error: i18nService.translate(
        'events-template.edit-template.expression-builder.invalid-expression',
        languageId
      ),
    };
  }

  // Now after we have validated the expression, let's check we don't divide by 0
  // for that, we will remove all spaces tokens, so we can check if prev token is / and current token is 0
  const tokensWithoutSpaces = tokens.filter((t) => !isSpacesToken(t));

  for (let i = 1; i < tokensWithoutSpaces.length; i++) {
    const currentToken = tokensWithoutSpaces[i];
    if (['/', '%'].includes(tokensWithoutSpaces[i - 1]) && isZeroToken(currentToken)) {
      return {
        isValid: false,
        error: i18nService.translate(
          'events-template.edit-template.expression-builder.division-by-zero',
          languageId
        ),
      };
    }
  }

  if (!expression && !allowEmpty) return { isValid: false, error: '' };

  return { isValid: true, error: '' };
};

export const convertExpressionToMentions = (html, dataSources, isTagTypes): string => {
  let localHtml = html || '';

  localHtml = `<p>${localHtml.replace(/\n/g, '<BR/>')}</p>`;

  const expressionDataSources = getExpressionDataSources(localHtml);

  if (expressionDataSources) {
    for (let dataSource of expressionDataSources) {
      localHtml = localHtml.replaceAll(
        `[${dataSource.groupName}.${dataSource.name}]`,
        getMentionHtmlByDataSourceName(dataSource.name, dataSources, isTagTypes)
      );
    }
  }
  return localHtml;
};

const getExpressionDataSources = (expression: string) => {
  const tempDataSources = expression.match(expressionDataSourceRegex) || [];

  const dataSources = tempDataSources.map((ds) => {
    const groupName = expressionBuilderGroupNames.find((name) => ds.includes(name));
    return {
      name: ds.replaceAll(`[${groupName}.`, '').replace(']', ''),
      groupName: groupName,
    };
  });

  return [...new Set(dataSources)];
};

const getMentionHtmlByDataSourceName = (dataSourceName, dataSources, isTagTypes) => {
  const dataSource = dataSources.find((d) => d.name === dataSourceName);
  const groupName = dataSource?.dataType
    ? dataSource?.dataType
    : isTagTypes
    ? 'tagTypes'
    : 'assetTags';

  if (dataSource) {
    return `<a href="datasource://${dataSource.id}" class="wysiwyg-mention" data-mention data-value="@data-type@${dataSource.dataType}.${dataSource.name}">#${dataSource.name}<\/a>`;
  } else {
    return `[${groupName}.${dataSourceName}]`;
  }
};

export function getGroupName(expression: string, index: number) {
  const groupName =
    expressionBuilderGroupNames.find((groupName) =>
      expression
        .substring(expression.indexOf('[') === 0 ? 1 : 0)
        .substring(index)
        .startsWith(`${groupName}.`)
    ) ||
    expressionBuilderGroupNames.find((groupName) =>
      expression.substring(expression.indexOf('[') === 0 ? 1 : 0).startsWith(`${groupName}.`)
    ) ||
    expressionBuilderGroupNames.find((groupName) =>
      expression.substring(expression.indexOf('[') + 1).startsWith(`${groupName}.`)
    );
  return groupName;
}

export function getNextIndex(expression: string, startIndex: number) {
  const newStartIndex =
    Math.min(
      ...expressionBuilderGroupNames
        .map((groupName) => expression.indexOf(`\[${groupName}.`, startIndex))
        .filter((index) => index >= 0)
    ) || 0;
  return isFinite(newStartIndex) ? newStartIndex : -1;
}
