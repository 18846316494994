import React, { useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import SceneContent from './SceneContent';
import styles from './AugmentedRealityEditorPage.scss';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SettingsOverscan from '@material-ui/icons/SettingsOverscan';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButtonWithText from '@components/IconButtonWithText';
import { Vector3 } from 'three';
import { i18nService } from '@core/i18n/I18nService';
import {
  combinedArWidgetInitialValueData,
  combinedArWidgetInitialImageByValueData,
} from './InitialProperties';

const CanvasWrapper = (prefs) => {
  const {
    onLoad,
    projectData,
    widgetData,
    widgetList,
    OnShowWidgetList,
    OnDeleteSelectedWidget,
    selectedWidgetId,
    handleWidgetClick,
    handleWidgetPositionChange,
    handleWidgetRotationChange,
    createWidgetOnDropPosition,
  } = prefs;
  const cameraControlsRef = useRef(null);
  const canvasRef = useRef(null);
  const sceneContentRef = useRef(null);
  const [isWidgetTypesVisible, setIsWidgetTypesVisible] = useState(false);

  const handleLoad = (gl: any, scene: any, camera: any, canvas: HTMLCanvasElement) => {
    if (onLoad) {
      onLoad(gl, scene, camera, canvas);
    }
  };

  const handleShowWidgetList = () => {
    setIsWidgetTypesVisible(!isWidgetTypesVisible);
  };

  const zoomIn = () => {
    if (sceneContentRef.current) {
      sceneContentRef.current.zoomIn();
    }
  };

  const zoomOut = () => {
    if (sceneContentRef.current) {
      sceneContentRef.current.zoomOut();
    }
  };

  const fitModelToScale = () => {
    if (sceneContentRef.current) {
      sceneContentRef.current.fitModelToScale();
    }
  };

  const handleDropTypedLabel = (e) => {
    const widgetType = e.dataTransfer.getData('widgetType');
    e.preventDefault();
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const vector = new Vector3((x / rect.width) * 2 - 1, -(y / rect.height) * 2 + 1, 0.5);

    let position = new Vector3(0, 0, 0);
    if (sceneContentRef.current) {
      position = sceneContentRef.current.getVector3PositionFromDropSpace(vector);
    }
    createWidgetOnDropPosition(
      position,
      sceneContentRef.current.getMaxModelDimension(),
      widgetType
    );
    setIsWidgetTypesVisible(false);
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <button
          title={i18nService.translate('general.widgets')}
          className={styles.controlButton}
          onClick={handleShowWidgetList}>
          <WidgetsIcon htmlColor={'var(--systemFont)'} />
        </button>
        <button
          title={i18nService.translate('general.zoom-in')}
          className={styles.controlButton}
          onClick={zoomIn}>
          <ZoomInIcon htmlColor={'var(--systemFont)'} />
        </button>
        <button
          title={i18nService.translate('general.zoom-out')}
          className={styles.controlButton}
          onClick={zoomOut}>
          <ZoomOutIcon htmlColor={'var(--systemFont)'} />
        </button>
        <button
          title={i18nService.translate('general.zoom-to-fit')}
          className={styles.controlButton}
          onClick={fitModelToScale}>
          <SettingsOverscan htmlColor={'var(--systemFont)'} />
        </button>
        <button
          title={i18nService.translate('general.delete')}
          className={styles.controlButton}
          hidden={selectedWidgetId == null || selectedWidgetId == 0}
          onClick={OnDeleteSelectedWidget}>
          <DeleteIcon htmlColor={'var(--systemFont)'} />
        </button>
      </div>
      <div className={styles.typeButtonContainer} hidden={!isWidgetTypesVisible}>
        <div
          draggable
          className={styles.controlButton}
          onDragStart={(e) =>
            e.dataTransfer.setData('widgetType', combinedArWidgetInitialValueData.type)
          }>
          <IconButtonWithText
            iconColor={'hsl(from var(--systemFont) h s l / 0.5)'}
            iconType="value"
            name="Value"
            showName
            tooltip={"Drag'n'drop widget"}
          />
        </div>
        <div
          draggable
          className={styles.controlButton}
          onDragStart={(e) =>
            e.dataTransfer.setData('widgetType', combinedArWidgetInitialImageByValueData.type)
          }>
          <IconButtonWithText
            iconColor={'hsl(from var(--systemFont) h s l / 0.5)'}
            iconType="imageByValue"
            name="Image by Value"
            showName
            tooltip={"Drag'n'drop widget"}
          />
        </div>
      </div>
      <div className={styles.sceneCanvas} onContextMenu={(e) => e.preventDefault()}>
        <Canvas
          shadows
          onDrop={handleDropTypedLabel}
          onDragOver={(e) => e.preventDefault()}
          camera={{ position: [3, 2, 3], up: [0, 1, 0] }}
          ref={canvasRef}>
          <SceneContent
            ref={sceneContentRef}
            onLoad={handleLoad}
            cameraControlsRef={cameraControlsRef}
            projectData={projectData}
            widgetData={widgetData}
            widgetList={widgetList}
            selectedWidgetId={selectedWidgetId}
            handleWidgetClick={handleWidgetClick}
            handleWidgetPositionChange={handleWidgetPositionChange}
            handleWidgetRotationChange={handleWidgetRotationChange}
            OnShowWidgetList={OnShowWidgetList}
          />
        </Canvas>
      </div>
    </>
  );
};

export default CanvasWrapper;
