import { EditorState, Modifier } from 'draft-js';
import React from 'react';
import styles from './RichTextEditorCustomButton.scss';
import { i18nService } from '@core/i18n/I18nService';

function RichTextEditorCustomButton(props) {
  const { editorState, onChange, htmlElementId = '' } = props;

  const addOperator = (value) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  function getTitle() {
    switch (props.children) {
      case '+':
        return 'expression-builder.syntax.addition';
      case '-':
        return 'expression-builder.syntax.subtraction';
      case '*':
        return 'expression-builder.syntax.multiplication';
      case '/':
        return 'expression-builder.syntax.division';
      case '%':
        return 'expression-builder.syntax.modulo';
      case '^2':
        return 'expression-builder.syntax.square';
      case '^':
        return 'expression-builder.syntax.power';
      case '√':
        return 'expression-builder.syntax.root';
      case '(':
        return 'expression-builder.syntax.left-parenthesis';
      case ')':
        return 'expression-builder.syntax.right-parenthesis';
      default:
        return '';
    }
  }

  return (
    <div
      id={htmlElementId}
      title={`${i18nService.translate(getTitle())}`}
      className={styles.button}
      style={props.style}
      onClick={(e) => addOperator(props.children)}>
      {props.children === '^2' ? (
        <div>
          x<sup>2</sup>
        </div>
      ) : props.children === '^' ? (
        <div>
          x<sup>y</sup>
        </div>
      ) : props.children === '%' ? (
        <div className={styles.wideOperator}>mod</div>
      ) : (
        props.children
      )}
    </div>
  );
}

export default RichTextEditorCustomButton;
