import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './EditFormulaExpressionModal.scss';
import classNames from 'classnames';
import Icon from '@components/Icon';
import Button from '@components/Button';
import RichTextEditor from '@pages/EventsManagementPage/EditTemplate/Message/RichTextEditor';
import {
  convertExpressionToMentions,
  convertHtmlToExpressionForConditionBuilder,
  validateExpression,
} from './EditFormulaExpressionModal.utils';
import RichTextEditorCustomButton from './RichTextEditorCustomButton/RichTextEditorCustomButton';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { convertPowerAndRootFromBE } from '@core/expressionUtilities/expressionUtilities';

const useStyle = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    overflowY: 'auto',
  },
  modalHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 28px',
    height: 56,
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'var(--systemFont)',
    backgroundColor: 'var(--systemPopupHeaderBackground)',
    justifyContent: 'space-between',
  },
}));

function EditFormulaExpressionModal(props) {
  const { dismiss, args } = props;
  const { expression, dataSources, isTagTypes, origininatesFrom, allowEmpty, tagsAllowed } = args;
  const classes = useStyle(props);
  const [isValidExpression, setIsValidExpression] = useState(false);
  const [expressionError, setExpressionError] = useState('');
  const [currentExpression, setCurrentExpression] = useState({
    expression: convertExpressionToMentions(
      convertPowerAndRootFromBE(expression),
      dataSources,
      isTagTypes
    ),
  });
  const languageId = useSelector((state) => state.config.languageId);
  const [isExpressionEmpty, setIsExpressionEmpty] = useState(false);

  useEffect(() => {
    const expressionValidationResult = validateExpression(
      currentExpression.expression,
      languageId,
      allowEmpty,
      origininatesFrom
    );
    setIsValidExpression(expressionValidationResult.isValid);
    setExpressionError(expressionValidationResult.error);

    expressionValidationResult.error && console.log(expressionValidationResult.error);
    setIsExpressionEmpty(
      !!(currentExpression.expression == '' || currentExpression.expression.includes('<p></p>'))
    );
  }, [currentExpression.expression, dataSources, allowEmpty]);

  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const save = useCallback(() => {
    dismiss({
      expression: convertHtmlToExpressionForConditionBuilder(currentExpression.expression),
    });
  }, [dismiss, currentExpression]);

  const setExpression = (expression) => {
    setCurrentExpression({ expression });
  };

  function onClearAll() {
    setCurrentExpression({ expression: '' });
  }

  const basicFeatures = [
    <RichTextEditorCustomButton
      htmlElementId={'2c6859ab-4cae-441f-8170-4ef56545f5bd'}
      style={{ marginLeft: '-1px', borderRadius: '3px 0 0 3px' }}>
      +
    </RichTextEditorCustomButton>,
    <RichTextEditorCustomButton
      htmlElementId={'31b8a46a-6635-4d3a-a28a-debdd7caa782'}
      style={{ marginLeft: '-1px' }}>
      -
    </RichTextEditorCustomButton>,
    <RichTextEditorCustomButton
      htmlElementId={'570bf161-b8f3-4bd1-8a14-905007f8457d'}
      style={{ marginLeft: '-1px' }}>
      *
    </RichTextEditorCustomButton>,
    <RichTextEditorCustomButton
      htmlElementId={'2dcfdbb3-4a27-45fc-8985-287cb1c6c828'}
      style={{ marginLeft: '-1px' }}>
      /
    </RichTextEditorCustomButton>,
    <RichTextEditorCustomButton
      htmlElementId={'2dcfdbb3-4a27-45fc-8985-287cb1c6c828'}
      style={{ marginLeft: '-1px' }}>
      %
    </RichTextEditorCustomButton>,
  ];
  const parenthesis = [
    <RichTextEditorCustomButton
      htmlElementId={'9ec0acae-b7c7-45f2-aa70-79528c7907d1'}
      style={{ marginLeft: '-1px' }}>
      (
    </RichTextEditorCustomButton>,
    <RichTextEditorCustomButton
      htmlElementId={'acae6f99-2f7c-4e98-ae3c-a77966477df1'}
      style={{ marginLeft: '-1px', marginRight: '5px', borderRadius: '0 3px 3px 0' }}>
      )
    </RichTextEditorCustomButton>,
  ];

  const customButtons = useMemo(() => {
    return origininatesFrom === 'conditionBuilder'
      ? [...basicFeatures, ...parenthesis]
      : [
          ...basicFeatures,
          <RichTextEditorCustomButton
            htmlElementId={'85b4d866-cae6-4553-87c0-81e1fb7fa8d7'}
            style={{ marginLeft: '-1px' }}>
            ^2
          </RichTextEditorCustomButton>,
          <RichTextEditorCustomButton
            htmlElementId={'dd58acbe-0423-4a49-92f4-b35e10f34f73'}
            style={{ marginLeft: '-1px' }}>
            ^
          </RichTextEditorCustomButton>,
          <RichTextEditorCustomButton
            htmlElementId={'e6edf1f5-8f0f-486d-8ea3-86a259ceb83b'}
            style={{ marginLeft: '-1px' }}>
            √
          </RichTextEditorCustomButton>,
          ...parenthesis,
        ];
  }, []);

  return (
    <div
      className={classes.wrapper}
      style={
        origininatesFrom === 'conditionBuilder'
          ? { minWidth: '622px', maxWidth: '622px' }
          : undefined
      }>
      <div className={classes.modalHeader}>
        <I18n>events-template.edit-template.expression-builder.modal-header</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <div className={styles.modalForm}>
        <div
          className={styles.modalContent}
          style={
            origininatesFrom === 'conditionBuilder'
              ? { minWidth: '586px', maxWidth: '586px' }
              : undefined
          }>
          <div className={styles.row}>
            <div id={'a157fc36-aa44-4b65-8464-d736790249e9'} className={styles.expression}>
              <RichTextEditor
                dataSources={dataSources}
                customButtons={customButtons}
                originatesFrom={origininatesFrom}
                value={currentExpression.expression}
                allowEnter={false}
                allowRichText={false}
                propertyName="expression"
                setTemplateData={setExpression}
                addDataSourceWidth={343}
                tagsAllowed={tagsAllowed}
              />
            </div>
          </div>
          <div className={styles.error}>{expressionError}</div>
        </div>
        <div className={styles.modalButtons}>
          {!isExpressionEmpty ? (
            <div
              id={'clearButton'}
              title={i18nService.translate('general.clear')}
              className={classNames(styles.clear, 'pointer', 'ellipsis-overflow')}
              onClick={onClearAll}>
              <I18n>general.clear</I18n>
            </div>
          ) : (
            <div className={classNames(styles.clear)}></div>
          )}
          <div className={styles.modalButtonsWrapper}>
            <Button htmlElementId={'cancelButton'} mode={['cancel', 'bigFont']} onClick={cancel}>
              <I18n>general.cancel</I18n>
            </Button>
            <Button
              htmlElementId={'saveButton'}
              styles={{ marginLeft: 13 }}
              mode="bigFont"
              disabled={!isValidExpression}
              onClick={save}>
              <I18n>general.save</I18n>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditFormulaExpressionModal;
