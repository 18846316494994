import { httpService } from '@core/http/HttpService';
import axios from 'axios';
import { ARProjectRes } from '@http/server.interface.d';
import { localToServer, serverToLocal } from '../CreateWidgetPage/widgets.utils';
import { setCustomizationTermsForBE } from '../CreateWidgetPage/CreateWidgetPage.utils';
import { initialStatesFormData, initialMetricsItem } from './InitialProperties';
import { tagFormatMap, variableFormatMap } from '@core/mapsAndDefinitions';
import * as THREE from 'three';
import { getCustomizationValidationByWidget } from './SidePanelToolbar/SidePanelToolbar.utils';

const buildFormData = (data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const uploadScreenshot = async (getUploadType, ArModelID, file, fileName) => {
  try {
    const uploadData: {
      uploadUrl: { fields: any; url: string };
      taskId: number;
    } = await httpService.api({
      type: getUploadType,
      data: { fileName, type: 'AR_MODEL' },
      urlParams: { ArModelID },
    });

    if (uploadData?.uploadUrl) {
      await axios({
        url: uploadData?.uploadUrl?.url,
        data: buildFormData({ ...uploadData?.uploadUrl?.fields, file }),
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
  } catch (e) {
    console.log({ e });
  }
};

export const validateUpdatedARProjectName = async (projectName, projectId) => {
  try {
    const response = await httpService.api<{ valid: boolean }>({
      type: 'arProjectValidateUpdatedName',
      data: { name: projectName },
      urlParams: { projectId: projectId },
      disableBI: true,
    });
    return response.valid;
  } catch (error) {
    console.error('Error validating project name', error);
    return false;
  }
};

export const updateProjectName = async (projectId, projectName) => {
  try {
    const response = await httpService.api<{ valid: boolean }>({
      type: 'arProjectNameUpdate',
      urlParams: { projectId },
      data: { name: projectName },
      disableBI: true,
    });
    return response;
  } catch (error) {
    console.error('Error validating project name', error);
    return false;
  }
};

export const updateProjectState = async (projectId, state) => {
  try {
    const response = await httpService.api<{ valid: boolean }>({
      disableBI: true,
      type: 'arProjectStateUpdate',
      urlParams: { projectId },
      data: state,
    });

    return response;
  } catch (error) {
    console.error('Error validating project name', error);
    return false;
  }
};

export const fetchProject = async (projectId, callback) => {
  try {
    await httpService
      .api<ARProjectRes>({
        type: 'getARProjectByID',
        urlParams: { projectId },
      })
      .then((projectInfoResult: any) => {
        callback(projectInfoResult);
      });
  } catch (error) {
    console.error('Error fetching project data', error);
  }
};

export const roundToDecimals = (position, decimals) => {
  return {
    x: Number(Number(position.x).toFixed(decimals)),
    y: Number(Number(position.y).toFixed(decimals)),
    z: Number(Number(position.z).toFixed(decimals)),
  };
};

export const createUpdateArWidget = async (
  projectData,
  widgetData,
  setWidgetList = null,
  callback = null
) => {
  if (widgetData.preventSave) {
    console.log('Saving canceled to prevent data loss');
    return;
  }
  const { statesForm, ...newWidgetWithoutStatesForm } = widgetData;
  const newWidget = localToServer(newWidgetWithoutStatesForm);
  const updatedMetrics = getMetricsFromWidgetData(newWidget);
  if (widgetData.id != null && widgetData.id != 0) {
    const { ...newWidgetWithoutPosition } = newWidget;
    await httpService
      .api<number>({
        type: 'updateArWidget',
        urlParams: { projectId: projectData.id, widgetId: widgetData.id },
        data: {
          ...newWidgetWithoutPosition,
          ...(updatedMetrics ? { metrics: updatedMetrics } : { metrics: [] }),
          assetTypes: [projectData.assetTypeId],
          customization: cleanCustomization(widgetData.customization),
          terms: setCustomizationTermsForBE(newWidgetWithoutPosition, true),
          states: [widgetData.statesForm],
        },
        disableBI: true,
      })
      .then((widgetsResult: any) => {
        if (callback) {
          callback(serverToLocal(widgetsResult));
        }
      });
    updateArWidgetState(projectData, widgetData);
  } else {
    await httpService
      .api<{ id: number }>({
        type: 'createNewArWidget',
        urlParams: { projectId: projectData.id, widgetId: widgetData.id },
        data: {
          ...newWidget,
          id: null,
          assetTypes: [projectData.assetTypeId],
          customization: cleanCustomization(widgetData.customization),
          terms: setCustomizationTermsForBE(newWidget, true),
          states: [widgetData.statesForm],
        },
      })
      .then((widgetsResult: any) => {
        if (callback) {
          callback(serverToLocal(widgetsResult));
        }
        setWidgetList &&
          getArWidgetList(projectData.id, (widgetsResult) => {
            const updatedWidgets = widgetsResult.map((widget) => ({
              ...widget,
              statesForm: widget.states[0],
            }));
            setWidgetList(updatedWidgets);
          });
      });
  }
};

const cleanCustomization = (customization: any) => {
  // Return a new object excluding the 'errors' property
  const { errors, ...cleanCustomization } = customization;
  return cleanCustomization;
};

export const getMetricsFromWidgetData = (widgetData) => {
  const { assetProperties, tags, variables } = widgetData;
  let newMetric = { ...initialMetricsItem };
  if (assetProperties && assetProperties.length > 0) {
    newMetric.valueId = assetProperties[0].id || assetProperties[0];
    newMetric.valueType = 'ASSET_PROPERTY';
    newMetric.type = tagFormatMap[assetProperties[0].type];
  } else if (tags && tags.length > 0) {
    newMetric.valueId = tags[0].id || tags[0];
    newMetric.valueType = 'TAG';
    newMetric.type = tagFormatMap[tags[0].type] || tagFormatMap[tags[0].format];
  } else if (variables && variables.length > 0) {
    newMetric.valueId = variables[0].id || variables[0];
    newMetric.valueType = 'VARIABLE';
    newMetric.type = variableFormatMap[variables[0].valueType];
  } else return null;
  return [newMetric];
};

export const updateArWidgetState = async (projectData, widgetData) => {
  if (widgetData.id) {
    await httpService.api({
      disableBI: true,
      type: 'updateArWidgetState',
      urlParams: { projectId: projectData.id, widgetId: widgetData.id },
      data: { ...widgetData.statesForm },
    });
  }
};

export const getArWidgetList = async (projectId, callback) => {
  try {
    await httpService
      .api({
        disableBI: true,
        type: 'getArWidgets',
        urlParams: { projectId: projectId },
      })
      .then((widgetsListResult: any) => {
        const updatedWidgetsList = widgetsListResult.map((widget) => ({
          ...widget,
          type: widget.type.toLowerCase(),
        }));
        callback(updatedWidgetsList);
      });
  } catch (error) {
    console.error('Error fetching WidgetList', error);
  }
};

export const getArWidget = async (projectId, widgetId, callback) => {
  try {
    await httpService
      .api({
        disableBI: true,
        type: 'getArWidget',
        urlParams: { projectId: projectId, widgetId: widgetId },
      })
      .then((widgetResult: any) => {
        const updatedMetrics = getMetricsFromWidgetData(widgetResult);
        const widgetData = {
          ...widgetResult,
          statesForm:
            widgetResult.states.length > 0 ? widgetResult.states[0] : initialStatesFormData,
          type: widgetResult.type.toLowerCase(),
          metrics: updatedMetrics ? updatedMetrics : [],
        };
        callback(widgetData);
      });
  } catch (error) {
    console.error('Error fetching AR Widget', error);
  }
};

export const deleteArWidget = async (projectId, widgetId) => {
  try {
    await httpService.api({
      type: 'deleteArWidget',
      urlParams: { projectId: projectId, widgetId: widgetId },
    });
  } catch (error) {
    console.error('Error deleting the widget', error);
  }
};

export const publishARProject = async (projectId, callback) => {
  try {
    httpService
      .api({
        type: 'publishArProject',
        urlParams: { projectId: projectId },
      })
      .then((publishResult: any) => {
        callback(publishResult);
      });
  } catch (error) {
    console.error('Error publishing the project', error);
    return false;
  }
};

export const trimVector3 = (vector3, precision = 5) => {
  const trimmedPosition = new THREE.Vector3(
    parseFloat(vector3.x.toFixed(precision)),
    parseFloat(vector3.y.toFixed(precision)),
    parseFloat(vector3.z.toFixed(precision))
  );
  return trimmedPosition;
};

export const isDataFormCompleted = (widgetData, step = null) => {
  const customizationValidation = getCustomizationValidationByWidget(widgetData);
  const isCustomizationValid = customizationValidation(widgetData);

  const isDataValid =
    widgetData.variables && widgetData.assetProperties && widgetData.tags
      ? widgetData.variables.length || widgetData.assetProperties.length || widgetData.tags.length
      : false;

  switch (step) {
    case 'data':
      return isDataValid;
    case 'customization':
      return isCustomizationValid;
    default:
      return isDataValid && isCustomizationValid;
  }
};

export const getWidgetDataStatus = (widgetData) => {
  if (isDataFormCompleted(widgetData)) {
    return 'PUBLISHED';
  }
  return 'DRAFT';
};
