import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import Button from '@components/Button';
import Close from '@material-ui/icons/Close';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './DisclaimerModal.scss';
import classnames from 'classnames';
import { useSelector } from '@redux/useSelector';
import { ModalComponentProps } from '@core/modals/modals.interface';
import Checkbox from '@components/Checkbox';
import classNames from 'classnames';

function DisclaimerModal(props: ModalComponentProps) {
  const { args, dismiss } = props;
  const {
    text,
    iconType,
    confirmText,
    cancelText,
    showCloseBtn,
    headerText,
    listText,
    secondText,
    style = {},
    ...rest
  } = args;
  const confirmBtn = useRef(null);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const close = useCallback(
    () => dismiss({ res: undefined, dontShowAgain }),
    [dismiss, dontShowAgain]
  );
  const cancel = useCallback(
    () => dismiss({ res: false, dontShowAgain }),
    [dismiss, dontShowAgain]
  );
  const confirm = useCallback(
    () => dismiss({ res: true, dontShowAgain }),
    [dismiss, dontShowAgain]
  );
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  useEffect(() => {
    confirmText && confirmBtn.current.focus();
  }, []);

  return (
    <div
      className={classnames(styles.wrapper, isMobileView && styles.wrapperMobile)}
      style={style ? style : {}}>
      {showCloseBtn && (
        <div className={styles.modalHeader}>
          <I18n {...rest}>{headerText}</I18n>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
      )}
      <div className={styles.content}>
        {iconType && <Icon className={styles.icon} type={iconType}></Icon>}
        <I18n element="div" noEllipsis className={styles.text} {...rest}>
          {text}
        </I18n>
        {secondText && (
          <I18n element="div" noEllipsis className={styles.text} {...rest}>
            {secondText}
          </I18n>
        )}
        {listText && listText.length > 0 && (
          <div className={styles.listText}>
            {listText.map((item, idx) => (
              <I18n element="div" noEllipsis className={styles.text} key={idx}>
                {item}
              </I18n>
            ))}
          </div>
        )}
      </div>
      <div className={styles.modalButtons}>
        <div
          className={styles.checkboxWrapper}
          onClick={(e) => {
            setDontShowAgain(!dontShowAgain);
          }}>
          <Checkbox
            onChange={(e) => setDontShowAgain(e.target.checked)}
            color="primary"
            checked={dontShowAgain}
          />
          <I18n className={classNames(styles.text, 'ellipsis-overflow', styles.checkboxLabel)}>
            general.dont-show-again
          </I18n>
        </div>

        <div className={styles.modalButtonsContainer}>
          {cancelText && (
            <Button onClick={cancel} styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']}>
              <I18n>{cancelText}</I18n>
            </Button>
          )}
          {confirmText && (
            <Button onClick={confirm} styles={{ marginLeft: 13 }} mode="bigFont" ref={confirmBtn}>
              <I18n>{confirmText}</I18n>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DisclaimerModal;
