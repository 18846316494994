import React, { useState, useEffect, useMemo, useRef } from 'react';
import MaterialCheckbox from '@components/Checkbox';
import styles from './MyDetails.scss';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import classNames from 'classnames';
import { httpService } from '@core/http/HttpService';
import { options } from '@components/TimezoneSelect/TimezoneOptions';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';
import { get2FAFieldsCfg, getFieldsCfg1, getFieldsCfg2, onSubmit } from './MyDetails.utils';
import { modalService } from '@core/modals/ModalService';
import { MyDetailsData } from './MyDetails.interface';
import { setUserSmsVertification } from '@pages/MainPage/MainPage.utils';

function MyDetails(props) {
  const [data, setData] = useState<MyDetailsData>({} as MyDetailsData);
  const [editMode, setEditMode] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const languageId = useSelector((state) => state.config.languageId);
  const isUnassigned = useSelector((state) => state.config.isUnAssigned);
  const [verifiedMobileChanged, setVerifiedMobileChanged] = useState(false);

  const titleOptions = useMemo(
    () =>
      ['NONE', 'MR', 'MRS', 'MISS', 'MS', 'DR', 'PROF'].map((opt) => ({
        value: opt,
        label: i18nService.translate(`enum.${opt}`),
      })),
    [languageId]
  );
  const [emailValidation, setEmailValidation] = useState({
    email: '',
    message: '',
    isValid: true,
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [saving, setSaving] = useState(false);
  const isValidEmailRef = useRef(null);
  isValidEmailRef.current = isValidEmail;
  const emailValidationRef = useRef(null);
  emailValidationRef.current = emailValidation;

  useEffect(() => {
    getMyProfile();
  }, []);

  const getMyProfile = async () => {
    const res: MyDetailsData = await httpService.api({ type: 'getMyProfile' });
    if (res) {
      setData(!res.middleName ? { ...res, middleName: '' } : res);
      setSendSMS(res.subscribeSMSNotification);
      setSendEmail(res.subscribeEmailNotification);
    }
  };

  const fieldsCfg1 = getFieldsCfg1(titleOptions);

  const fieldsCfg2 = getFieldsCfg2(
    emailValidation,
    data,
    setEmailValidation,
    setIsValidEmail,
    options
  );

  const mfaFieldsCfg = get2FAFieldsCfg(
    data.mfa,
    data.mobileVerified,
    verifiedMobileChanged,
    isUnassigned
  );

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const validate = (values) =>
    [...fieldsCfg1, ...fieldsCfg2].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );

  const onUnsubscribeSMS = async () => {
    const confirm = await modalService.openModal('confirm', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: sendSMS
        ? 'my-details.sms-notifications.unsubscribe-popup-title'
        : 'my-details.sms-notifications.subscribe-popup-title',
      text: sendSMS
        ? 'my-details.sms-notifications.unsubscribe-popup-text'
        : 'my-details.sms-notifications.subscribe-popup-text',
      showCloseBtn: true,
      style: { width: 410 },
    });
    if (confirm) {
      setSendSMS(!sendSMS);
      unsubscribe('SMS', !sendSMS);
    }
  };

  const onUnsubscribeEmail = async () => {
    const confirm = await modalService.openModal('confirm', {
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: sendEmail
        ? 'my-details.email-notifications.unsubscribe-popup-title'
        : 'my-details.email-notifications.subscribe-popup-title',
      text: sendEmail
        ? 'my-details.email-notifications.unsubscribe-popup-text'
        : 'my-details.email-notifications.subscribe-popup-text',
      showCloseBtn: true,
      style: { width: 420 },
    });
    if (confirm) {
      setSendEmail(!sendEmail);
      unsubscribe('EMAIL', !sendEmail);
    }
  };

  const unsubscribe = async (notificationType: string, subscribe: boolean) => {
    await httpService.api({
      type: 'unsubscribeNotification',
      data: { notificationType, subscribe },
    });
  };

  const startMobileValidation = async () => {
    await setUserSmsVertification(data);
    await getMyProfile();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...data, enable: editMode }}
      validationSchema={validationSchema([...fieldsCfg1, ...fieldsCfg2])}
      validate={validate}
      onSubmit={(values) =>
        onSubmit(
          values,
          data,
          isValidEmailRef,
          emailValidationRef,
          setEditMode,
          setData,
          setSaving,
          setIsValidEmail
        )
      }>
      {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
        return (
          <FormikForm className={styles.form}>
            <div className={styles.box}>
              {!editMode && (
                <Icon
                  type="edit"
                  color="var(--systemFont)"
                  className={classNames('pointer', styles.editBtn)}
                  onClick={() => setEditMode(!editMode)}
                  disabled={data?.email === 'unidemo@unitronics.com'}
                />
              )}
              <div className={classNames(styles.details, editMode && styles.editMode)}>
                <div className={classNames(styles.boxBody, styles.spaceBox)}>
                  {fieldsCfg1.map((field, idx) => (
                    <FormikField key={idx} editMode={editMode} {...field} values={values} />
                  ))}
                </div>
                <div className={classNames(styles.boxBody, styles.spaceBox)}>
                  {fieldsCfg2.map((field, idx) => (
                    <div key={idx} className={styles.field}>
                      <FormikField
                        key={idx}
                        editMode={editMode}
                        {...field}
                        values={values}
                        setFieldValue={setFieldValue}
                        showVertificationStatus={field.name === 'mobile'}
                        mobileVerified={data?.mobileVerified}
                        allowMobileValidation={startMobileValidation}
                        isUnassigned={isUnassigned}
                      />
                      {field.name === 'email' && data['pendingEmail'] && (
                        <div className={styles.pendingEmail}>
                          <I18n>edit-user-modal.pending-email</I18n>
                          <span className={styles.pendingEmailText}>{values.pendingEmail}</span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className={classNames(styles.boxBody)}>
                  <div className={styles.unsubscribeContainer}>
                    <MaterialCheckbox
                      color="primary"
                      checked={!sendSMS}
                      onChange={onUnsubscribeSMS}
                    />
                    <I18n className={styles.unsubscribe}>my-details.sms-notifications</I18n>
                  </div>

                  <div className={styles.unsubscribeContainer}>
                    <MaterialCheckbox
                      color="primary"
                      checked={!sendEmail}
                      onChange={onUnsubscribeEmail}
                    />
                    <I18n className={styles.unsubscribe}>my-details.email-notifications</I18n>
                  </div>

                  {data.mfa && (
                    <div className={styles.field}>
                      <FormikField
                        editMode={editMode}
                        {...mfaFieldsCfg}
                        labelClassName={styles.longLabel}
                        inputClassName={styles.shortSelect}
                        values={values}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {editMode && (
              <div className={styles.footerButtons}>
                <Button
                  styles={{ width: 92, marginRight: 13 }}
                  mode="cancel"
                  onClick={() => setEditMode(false)}>
                  <I18n>general.cancel</I18n>
                </Button>
                <Button styles={{ width: 92 }} type="submit" disabled={!isValid || saving}>
                  <I18n>general.save</I18n>
                </Button>
              </div>
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
}

export default MyDetails;
