import React from 'react';
import I18n from '@components/I18n';
import styles from './SidePanelToolbar.scss';
import { InputBase } from '@material-ui/core';
import MaterialCheckbox from '@components/Checkbox';
import { initialCustomizationFormData } from '../InitialProperties';
import SketchColorInput from '@components/SketchColorInput';

function ValueCustomizationPanel(props) {
  const { widgetData, widgetList, setWidgetData, setWidgetList, onUpdate } = props;
  if (widgetData.customization == null) {
    widgetData.customization = initialCustomizationFormData;
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const nameParts = name.split('.');

    // Check if the value should be formatted
    const isDecimalInput = name === 'customization.decimalDigits';
    const formattedValue = isDecimalInput ? +value : value;
    const newFormData = { ...widgetData };

    if (nameParts.length === 1) {
      // Handle top-level properties
      newFormData[name] = type === 'checkbox' ? checked : formattedValue;
    } else {
      // Handle nested properties
      let nestedProperty = newFormData;

      for (let i = 0; i < nameParts.length - 1; i++) {
        nestedProperty[nameParts[i]] = { ...nestedProperty[nameParts[i]] };
        nestedProperty = nestedProperty[nameParts[i]];
      }

      nestedProperty[nameParts[nameParts.length - 1]] =
        type === 'checkbox' ? checked : formattedValue;
    }

    setWidgetData(newFormData);

    const updateWidgetList = () => {
      const updatedWidgets = widgetList.map((widget) =>
        widget.id === widgetData.id ? { ...widget, ...newFormData } : widget
      );
      setWidgetList(updatedWidgets);
    };

    updateWidgetList();
    onUpdate(newFormData);
  };

  let isDecimalDigitsHidden = true;
  if (widgetData.tags.length > 0) {
    if (widgetData.tags[0].type === 'FLOAT32' || widgetData.tags[0].format === 'FLOAT32')
      isDecimalDigitsHidden = false;
  }
  if (widgetData.assetProperties.length > 0) {
    if (widgetData.assetProperties[0].type === 'FLOAT32') isDecimalDigitsHidden = false;
  }
  if (widgetData.variables.length > 0) {
    if (
      widgetData.variables[0].valueType === 'FLOAT32' ||
      widgetData.variables[0].valueType === 'NUMERIC'
    )
      isDecimalDigitsHidden = false;
  }

  return (
    <>
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>
          <I18n>augmented-reailty.widget.side-panel.title-field</I18n>
        </label>
        <InputBase
          id={styles.widgetNameInput}
          className={styles.containerInput}
          name="name"
          value={widgetData.name}
          onChange={handleChange}
          inputProps={{ maxLength: 50 }}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.checkboxGroup}>
          <MaterialCheckbox
            name="hideWidgetName"
            checked={widgetData.hideWidgetName}
            color="primary"
            onChange={handleChange}
          />
          <label className={styles.fieldLabelRow}>
            <I18n>augmented-reailty.widget.side-panel.hidetitle-field</I18n>
          </label>
        </div>
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.checkboxGroup}>
          <MaterialCheckbox
            name="customization.lookAtMe.enabled"
            checked={widgetData.customization.lookAtMe?.enabled}
            color="primary"
            onChange={handleChange}
          />
          <label className={styles.fieldLabelRow}>
            <I18n>augmented-reailty.widget.side-panel.lookatme-enabled-field</I18n>
          </label>
        </div>
      </div>
      <div className={styles.fieldWrapper}>
        <SketchColorInput
          label="augmented-reailty.widget.side-panel.textcolor-field"
          name="customization.valueColor"
          value={widgetData.customization.valueColor}
          onChangeComplete={handleChange}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <SketchColorInput
          label="augmented-reailty.widget.side-panel.background-field"
          name="customization.backgroundColor"
          value={widgetData.customization.backgroundColor}
          onChangeComplete={handleChange}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.checkboxGroup}>
          <MaterialCheckbox
            name="customization.transparentBackground"
            checked={widgetData.customization.transparentBackground}
            color="primary"
            onChange={handleChange}
          />
          <label className={styles.fieldLabelRow}>
            <I18n>augmented-reailty.widget.side-panel.transparentbackground-field</I18n>
          </label>
        </div>
      </div>
      {!isDecimalDigitsHidden ? (
        <div className={styles.fieldWrapper}>
          <label className={styles.fieldLabelRow}>
            <I18n>augmented-reailty.widget.side-panel.decimaldigits-field</I18n>
          </label>
          <InputBase
            className={styles.smallContainerInput}
            type="number"
            name="customization.decimalDigits"
            value={widgetData.customization.decimalDigits}
            onChange={(e) => {
              if (+e.target.value > 9) return;
              handleChange(e);
            }}
            inputProps={{ maxLength: 50, min: 0, max: 10, step: 1 }}
          />
        </div>
      ) : null}
      <div className={styles.fieldWrapper}>
        <label className={styles.fieldLabelRow}>
          <I18n>augmented-reailty.widget.side-panel.symbol-field</I18n>
        </label>
        <InputBase
          className={styles.smallContainerInput}
          name="customization.symbol"
          value={widgetData.customization.symbol}
          onChange={handleChange}
          inputProps={{ maxLength: 50 }}
        />
      </div>
    </>
  );
}

export default ValueCustomizationPanel;
